module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chiro Itterbeek","short_name":"Chiro Itterbeek","description":"Chiro Itterbeek is een jeugdbeweging gesitueerd in Dilbeek, rond Brussel. Chiro Itterbeek verwelkomt alle leeftijden tussen 6 en 18 jaar, om er samen een leuke zondagnamiddag van te maken!","start_url":"/","background_color":"#272728","theme_color":"#E1143C","display":"browser","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f85a076a0e83b7c82330a0ec6f636ed8"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
